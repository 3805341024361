/**
 * Track.cred.ai JavaScript
 */
(function ($) {
  // Expand/collapse objects in listing templates
  $(document).on("click", `[data-toggle-object]`, (event) => {
    const $this = $(event.currentTarget);
    const id = $this.data("toggle-object");
    const $target = $(`[data-object="${id}"]`);
    const $row = $this.parents(`[data-row]`);

    if ($target.is(":visible")) {
      $target.hide();
    } else {
      $(`[data-object]`, $row).hide();
      $target.css("display", "grid");
    }
  });
})(window.jQuery);
